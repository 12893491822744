import React from 'react';


import ManualList from './ManualList';
import Filter from './filter'

//This will be changed
class Filterresults extends React.Component {
    constructor(props){
        super(props);
        this.state = { array: props.array.entries}
        this.location = props.location
        this.Filter = React.createRef();
    };

    onClearManualList = () =>{
        this.Filter.current.clearFilter();
    }
    onFilterChange(filter){
        this.setState({
            array: filter
        })
    }

    render(){
        return(
            <div className="wrapper">
                <Filter array={this.state.array} manufacture={this.props.array.manufact} eletype={this.props.array.eletype} language={this.props.array.langue} onFilterChange={this.onFilterChange.bind(this)} location={this.location} ref={this.Filter} />
                <ManualList array={this.state.array} onClearManualList={this.onClearManualList}/>
            </div>



        )
    }

}

export default Filterresults;

//<ManualList array={this.state.array} />
