import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import * as ROUTES from '../constants/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { graphql } from 'gatsby';

import SignInForm from '../components/SignIn';
import OnSendEmailVerificationButton from '../components/Session/onSendEmailVerification';
import { SignUpLink } from '../components/SignUp';
import { PasswordForgetLink } from '../components/PasswordForget';
import { AuthUserContext } from '../components/Session';

import Layout from '../App/components/layout';

import Filterresults from '../App/components/Filterresults';
import SEO from "../App/components/seo"


const filterResults = ({data, location}) => (
    <Layout>
      <SEO title="CECA-ACEA MCP" />
      <AuthUserContext.Consumer>
      {authUser =>
        authUser ? (
          authUser.emailVerified ? (

              <div className="wrapper">
                <Link to={ROUTES.INDEX} className="back-btn"><FontAwesomeIcon className="back" icon={faArrowLeft} />Return to Manuals</Link>
                <h1>Manual</h1>
              <Filterresults array={data.craft} location={location} />
              </div>
        ) : (
          <OnSendEmailVerificationButton/>
        )
        ) : (
          <Fragment>
              <SignInForm />
              <div className="wrapper maxWidth"><PasswordForgetLink /></div>
              <SignUpLink />
            </Fragment>
        )}
        </AuthUserContext.Consumer>

        </Layout>
)
export default filterResults;

export const data = graphql`
query{
  craft{
    manufact: categories(group: manufacture, orderBy:"title asc" ){
      ...on Craft_ManufactureCategory{
        title
        asset{
          url
          id
        }
      }
    }
    eletype: categories(group: elevatorType, orderBy:"title asc"){
      ...on Craft_ElevatorTypeCategory{
        title
        asset{
          url
          id
        }
      }
    }
    langue: categories(group: language, orderBy:"title asc"){
      ...on Craft_LanguageCategory{
        title
      }
    }
    entries(type: Manuals, orderBy:"title asc"){
      ...on Craft_Manuals{
        title
        slug
        uri
        elevatorType{
          title
        }
        elevatorManufacturer{
          title
        }
        language{
          title
        }
        manual{
          url
        }
      }
    }
  }
}
`;

/*

*/
